import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { NetIntentService } from 'services/net-intent-service';

@autoinject
export class NetIntentList {
  private tableData: Array<Models.NetIntent>;
  private activeTableRow: number;
  private ready: boolean = false;

  constructor(
    private netIntentService: NetIntentService,
    private errorService: ErrorService,
    private router: Router,
    private eventAggregator: EventAggregator
  ) {}

  private activate(params) {
    if (params.Id) {
      this.activeTableRow = params.Id;
    } else {
      this.activeTableRow = 0;
    }
  }

  private attached(params) {
    this.eventAggregator.subscribe('netIntentListReset', (value) => {
      this.activeTableRow = null;
      return this.updateView();
    });
    this.updateView();
  }

  private updateView() {
    this.netIntentService
      .getAll()
      .then((res) => {
        this.ready = true;
        this.tableData = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private gotoDetailView(id) {
    this.activeTableRow = id;
    this.router.navigateToRoute('net-intent-detail', { Id: id });
  }
}
