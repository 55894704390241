import { autoinject, bindable } from 'aurelia-framework';
import { Models } from 'models/core';

@autoinject
export class MooringCalculationReportForm {
  @bindable private report: Models.MooringCalculationReport;

  private standards = [
    {
      Id: 1,
      Name: 'NS 9415:2009'
    },
    {
      Id: 2,
      Name: 'Scottish Technical Standard'
    }
  ];

  constructor() {

  }
}
