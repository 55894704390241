import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { Models } from "models/core";
import { NetIntentService } from "services/net-intent-service";
import { autoinject } from "aurelia-framework";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator";

@autoinject
export class NetIntentNew {
  private netintent: Models.NetIntent = new Models.NetIntent();

  constructor(
    private netIntentService: NetIntentService, 
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  createNetIntent() {
    this.netIntentService
      .post(this.netintent)
      .then(res => {
        this.eventAggregator.publish("netIntentListReset", 1);
        this.toastService.showSuccess("netintent.created");
        this.router.navigateToRoute("net-intent-detail", { Id: res.Id });
      })
      .catch(err => this.errorService.handleError(err));
  }
}
