import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { MeshSizeService } from 'services/mesh-size-service';

@autoinject
export class MeshSizeList {
  private tableData: Array<Models.MeshSize>;
  private activeTableRow: number;
  private ready: boolean = false;

  constructor(
    private errorService: ErrorService,
    private meshSizeService: MeshSizeService,
    private router: Router
  ) {

  }
  private attached() {
    this.meshSizeService.getAll('?$orderby=MeshSizeMm')
      .then((res) => {
        this.ready = true;
        this.tableData = res;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private gotoDetailView(id) {
    this.activeTableRow = id;
    this.router.navigateToRoute('mesh-size-detail', { Id: id });
  }
}
