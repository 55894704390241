import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { Models } from "models/core";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator"; 
import { NetTypeService } from "services/net-type-service";

@autoinject
export class netTypeDetail {
  private nettype: Models.NetType;

  constructor(
    private netTypeService: NetTypeService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private deleteDialogService: DeleteDialogService
  ) {}

  activate(params) {
    this.netTypeService
      .get(params.Id)
      .then(netType => {
        this.nettype = netType;
      })
      .catch(err => this.errorService.handleError(err));
  }

  updateNetType() {
    this.netTypeService
      .put(this.nettype, this.nettype.Id)
      .then(res => {
        this.eventAggregator.publish("netTypeListReset", 1);
        this.toastService.showSuccess("nettype.updated");
        this.router.navigateToRoute("net-type-list");
      })
      .catch(err => this.errorService.handleError(err));
  }

  deleteNetType() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.netTypeService
          .delete(this.nettype.Id)
          .then(res => {
            this.eventAggregator.publish("netTypeListReset", 1);
            this.toastService.showSuccess("nettype.deleted");
            this.router.navigateToRoute("net-type-list");
          })
          .catch(err => this.errorService.handleError(err));
        }
      );   
  }
  
  gotoParentView(){
    this.eventAggregator.publish("netTypeListReset", 1);
    this.router.navigateToRoute("net-type-list");
  }
}
