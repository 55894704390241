import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { Models } from "models/core";
import { NetShapeService } from "services/net-shape-service";
import { autoinject } from "aurelia-framework";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator";

@autoinject
export class NetShapeNew {
  private netshape: Models.NetShape = new Models.NetShape();

  constructor(
    private netShapeService: NetShapeService, 
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator
  ) {}

  createNetShape() {
    this.netShapeService
      .post(this.netshape)
      .then(res => {
        this.eventAggregator.publish("netShapeListReset", 1);
        this.toastService.showSuccess("netshape.created");
        this.router.navigateToRoute("net-shape-detail", { Id: res.Id });
      })
      .catch(err => this.errorService.handleError(err));
  }
}
