import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { NetShapeService } from "services/net-shape-service";
import { Models } from "models/core";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator"; 

@autoinject
export class netShapeDetail {
  private netshape: Models.NetShape;

  constructor(
    private netShapeService: NetShapeService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator
  ) {}

  activate(params) {
    this.netShapeService
      .get(params.Id)
      .then(netShape => {
        this.netshape = netShape;
      })
      .catch(err => this.errorService.handleError(err));
  }

  updateNetShape() {
    this.netShapeService
      .put(this.netshape, this.netshape.Id)
      .then(res => {
        this.eventAggregator.publish("netShapeListReset", 1);
        this.toastService.showSuccess("netshape.updated");
        this.router.navigateToRoute("net-shape-list");
      })
      .catch(err => this.errorService.handleError(err));
  }

  deleteNetShape() {
    this.netShapeService
      .delete(this.netshape.Id)
      .then(res => {
        this.eventAggregator.publish("netShapeListReset", 1);
        this.toastService.showSuccess("netshape.deleted");
        this.router.navigateToRoute("net-shape-list");
      })
      .catch(err => this.errorService.handleError(err));
  }
  gotoParentView(){
    this.eventAggregator.publish("netShapeListReset", 1);
    this.router.navigateToRoute("net-shape-list");   
  }
}
