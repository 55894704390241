import { ErrorService } from 'services/error-service';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { MeshSizeService } from 'services/mesh-size-service';
import { autoinject } from 'aurelia-framework';
import { ToastService } from 'services/toast-service';

@autoinject
export class MeshSizeNew {
  private meshsize: Models.MeshSize = new Models.MeshSize;

  constructor(private meshSizeService: MeshSizeService, private router: Router, private toastService: ToastService, private errorService: ErrorService) { }

  createMeshSize() {
    this.meshSizeService.post(this.meshsize)
      .then(res => {
        this.toastService.showSuccess("meshsize.created");
        this.router.navigateToRoute('mesh-size-detail', { Id: res.Id });
      })
      .catch(err => this.errorService.handleError(err));;
  }
}
