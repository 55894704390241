import { DeleteDialogService } from 'services/delete-dialog-service';
import { ErrorService } from "services/error-service";
import { Router } from "aurelia-router";
import { autoinject } from "aurelia-framework";
import { NetIntentService } from "services/net-intent-service";
import { Models } from "models/core";
import { ToastService } from "services/toast-service";
import { EventAggregator } from "aurelia-event-aggregator"; 

@autoinject
export class netIntentDetail {
  private netintent: Models.NetIntent;

  constructor(
    private netIntentService: NetIntentService,
    private errorService: ErrorService,
    private router: Router,
    private toastService: ToastService,
    private eventAggregator: EventAggregator,
    private deleteDialogService: DeleteDialogService
  ) {}

  activate(params) {
    this.netIntentService
      .get(params.Id)
      .then(netIntent => {
        this.netintent = netIntent;
      })
      .catch(err => this.errorService.handleError(err));
  }

  updateNetIntent() {
    this.netIntentService
      .put(this.netintent, this.netintent.Id)
      .then(res => {
        this.eventAggregator.publish("netIntentListReset", 1);
        this.toastService.showSuccess("netintent.updated");
        this.router.navigateToRoute("net-intent-list");
      })
      .catch(err => this.errorService.handleError(err));
  }

  deleteNetIntent() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {
        this.netIntentService
          .delete(this.netintent.Id)
          .then(res => {
            this.eventAggregator.publish("netIntentListReset", 1);
            this.toastService.showSuccess("netintent.deleted");
            this.router.navigateToRoute("net-intent-list");
          })
          .catch(err => this.errorService.handleError(err));
        }
      );    
  }

  gotoParentView(){
    this.eventAggregator.publish("netIntentListReset", 1);
    this.router.navigateToRoute("net-intent-list");   
  }
}
