import { DeleteDialogService } from 'services/delete-dialog-service';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { MeshSizeService } from 'services/mesh-size-service';
import { ToastService } from 'services/toast-service';

@autoinject
export class MeshSizeDetail {
  private meshsize: Models.MeshSize;

  constructor(
    private errorService: ErrorService,
    private meshSizeService: MeshSizeService,
    private router: Router,
    private toastService: ToastService,
    private deleteDialogService: DeleteDialogService
  ) {}

  private activate(params) {
    this.meshSizeService
      .get(params.Id)
      .then((meshSize) => {
        this.meshsize = meshSize;
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private updateMeshSize() {
    this.meshSizeService
      .put(this.meshsize, this.meshsize.Id)
      .then((res) => {
        this.toastService.showSuccess('meshsize.updated');
        this.router.navigateToRoute('mesh-size-list');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private deleteMeshSize() {
    this.deleteDialogService.confirmBeforeDelete(
      () => {  
        this.meshSizeService
          .delete(this.meshsize.Id)
          .then((res) => {
            this.toastService.showSuccess('meshsize.deleted');
            this.router.navigateToRoute('mesh-size-list');
          })
          .catch((err) => this.errorService.handleError(err));          
        }
      ); 
  }
}
