import { FileService } from 'services/file-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { MooringCalculationReportService } from 'services/mooring-calculation-report-service';
import { ToastService } from 'services/toast-service';
import { ValidationRules, ValidationController, Validator } from 'aurelia-validation';
import { I18N } from 'aurelia-i18n';

@autoinject
export class MooringCalculationReportNew {
  
  private report: any;
  private progress: boolean = false;

  constructor(
    private mooringCalculationReportService: MooringCalculationReportService,
    private fileService: FileService,
    private router: Router,
    private toastService: ToastService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private i18n: I18N,
    private validationController: ValidationController,
    private validator: Validator,
  ) { 

    this.report = {
      /*TEST 
       CustomerName: 'Appex AS',
      Address: 'Decobygget',
      ContactName: 'Kjetil Ek',
      EmailAddress: 'kjetil.ek@appex.no',
      PhoneNumber: '99389923',
      SiteName: 'Decobygget',
      CageCountLenghtDirection : 7,
      CageCountTransDirection: 2,
      Depth: 27,
      DepthBottomRope: 16,
      Solidity: 0.2,
      PlumbWeight: 9700,
      CircumferenceCage: 120,
      VcEnd1: 0.68,
      HsEnd1: 3.73,
      WaterDepthEnd1 : 30,
      LineLengthEnd1 : 300,
      VcSide1: 0.49, 
      HsSide1: 2.39,
      WaterDepthSide1 : 30,
      LineLengthSide1 : 215,
      VcEnd2: 0.69,
      HsEnd2: 2.25,
      WaterDepthEnd2 : 30,      
      LineLengthEnd2 : 145,
      VcSide2: 0.48,
      HsSide2: 0.97,
      WaterDepthSide2 : 30,      
      LineLengthSide2 : 125,
      LatitudeCorner1: 59.396780,
      LongitudeCorner1: 5.330170,
      LatitudeCorner2: 59.39673,
      LongitudeCorner2: 5.330172,
      LatitudeCorner3: 59.39676,
      LongitudeCorner3: 5.330174,
      LatitudeCorner4: 59.39678,
      LongitudeCorner4: 5.330179,
        */
      // these values remain hardcoded for now:
      TechnicalStandardId: 1,
      SideCount : 24
    };

    ValidationRules
    .ensure('TechnicalStandardId').required().withMessage(this.i18n.tr('general.requiredField'))
    .ensure('CageCountLenghtDirection')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .satisfies((value, obj: any) => +value <= 10)
      .withMessage('Max 10')
    .ensure('CageCountTransDirection')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .satisfies((value, obj: any) => +value <= 2)
      .withMessage('Max 2')
    .ensure('CircumferenceCage').required().withMessage(this.i18n.tr('general.requiredField'))
    .ensure('Depth').required().withMessage(this.i18n.tr('general.requiredField'))
      .satisfies((value, obj: any) => obj.Depth > obj.DepthBottomRope)
      .withMessage('Total depth must be larger than depth base rope')
    .ensure('DepthBottomRope').required().withMessage(this.i18n.tr('general.requiredField'))
    .ensure('Solidity')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .satisfies((value, obj: any) => +value <= 0.35)
      .withMessage('Max 0.35')
    .ensure('PlumbWeight').required().withMessage(this.i18n.tr('general.requiredField'))
    .ensure('VcEnd1')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))    
      .satisfies((value, obj: any) => this.isValidVc(value, obj))
      .withMessage('Max 1.25')    
    .ensure('VcSide1')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))    
      .satisfies((value, obj: any) => this.isValidVc(value, obj))
      .withMessage('Max 1.25')
    .ensure('VcEnd2')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .satisfies((value, obj: any) => this.isValidVc(value, obj))
      .withMessage('Max 1.25')
    .ensure('VcSide2')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .satisfies((value, obj: any) => this.isValidVc(value, obj))
      .withMessage('Max 1.25')
    .ensure('HsEnd1')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .satisfies((value, obj: any) => this.isValidHs(value, obj))
      .withMessage('Max 4.5')
    .ensure('HsSide1')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .satisfies((value, obj: any) => this.isValidHs(value, obj))
      .withMessage('Max 4.5')
    .ensure('HsEnd2')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .satisfies((value, obj: any) => this.isValidHs(value, obj))
      .withMessage('Max 4.5')
    .ensure('HsSide2')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .satisfies((value, obj: any) => this.isValidHs(value, obj))
      .withMessage('Max 4.5')
    .ensure('WaterDepthEnd1').required().withMessage(this.i18n.tr('general.requiredField'))
    .ensure('WaterDepthEnd2').required().withMessage(this.i18n.tr('general.requiredField'))
    .ensure('WaterDepthSide1').required().withMessage(this.i18n.tr('general.requiredField'))
    .ensure('WaterDepthSide2').required().withMessage(this.i18n.tr('general.requiredField'))
    .ensure('LineLengthEnd1')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .satisfies((value, obj: any) => +obj.LineLengthEnd1 > +obj.WaterDepthEnd1)
      .withMessage('Line must be larger than depth')
    .ensure('LineLengthEnd2')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .satisfies((value, obj: any) => +obj.LineLengthEnd2 > +obj.WaterDepthEnd2)
      .withMessage('Line must be larger than depth')
    .ensure('LineLengthSide1')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .satisfies((value, obj: any) => +obj.LineLengthSide1 > +obj.WaterDepthSide1)
      .withMessage('Line must be larger than depth')
    .ensure('LineLengthSide2')
      .required()
      .withMessage(this.i18n.tr('general.requiredField'))
      .satisfies((value, obj: any) => +obj.LineLengthSide2 > +obj.WaterDepthSide2)
      .withMessage('Line must be larger than depth')
    .on(this.report);
  }

  private isValidVc(value, object): boolean {
    let number = +value;
    if (number >= 0 && number <= 1.25) {
      return true;
    }
    return false;
  }

  private isValidHs(value, object): boolean {
    let number = +value;
    if (number >= 0 && number <= 4.5) {
      return true;
    }
    return false;
  }

  private validateBeforeGenerate() {
    // MANUAL VALIDATION
    this.validator.validateObject(this.report)
      .then((result) => {
        const errors = result.filter((validateResult) => {
          return !validateResult.valid;
        });

        if (errors.length > 0) {
          this.validationController.validate();
          
          let allErrors = errors.map(x => x.propertyName + ': ' + x.message).join('<br/>');
          this.toastService.showError(null, 'general.fillRequiredFields', allErrors);
        } else {
          this.generateMooringCalculationReport();
        }
      });
  }

  private generateMooringCalculationReport() {
    this.progress = true;
      
    this.mooringCalculationReportService
      .generateReport(this.report)
      .then((res) => {        

        this.toastService.showSuccess('mooringCalculationReport.created');

        // download result file
        this.fileService.download(res.ReportFileId, res.CustomerId)
          .then(res => {
            this.progress = false;
          });          
      })
      .catch((err) => {
        this.errorService.handleError(err);
        this.progress = false;
      });
  }
}
